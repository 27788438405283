export default {
  data() {
    return {
      iva_cliente: "",
    };
  },
  computed: {
    chats() {
      return this.$store.getters.get_chats_archivio.sort((a, b) => b.ts_ultimo_msg - a.ts_ultimo_msg);
    },
    clienti() {
      return this.$store.getters.get_clienti;
    },
  },
  methods:{
    ricerca() {
      if (!this.iva_cliente) {
        this.$store.dispatch("fetch_chats_archivio");
      } else {
        this.$store.dispatch("fetch_chats_by_iva", {
          iva: this.iva_cliente,
        });
      }
    },
  }
};
