<template>
  <v-card height="400">
    <v-card-title class="grey--text text-subtitle-1">
      Messaggi Archiviati
      <v-autocomplete
        v-model="iva_cliente"
        :items="clienti"
        hide-details
        outlined
        dense
        rounded
        label="Cerca..."
        class="ml-4"
        item-text="nome"
        item-value="iva"
        @change="ricerca()"
        clearable
      ></v-autocomplete>
    </v-card-title>
    <v-card-text class="scroll">
      <v-list>
        <MessaggioEl v-for="msg in chats" :key="msg.id" :chat="msg" />
        <!-- <div v-for="msg in chats" :key="msg.id">
            {{msg.messaggi}}
        </div> -->
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import messaggi_mixin_archivio from "../mixin/messaggi_mixin_archivio";
import MessaggioEl from "./messaggio_element_archivio.vue";
export default {
  components: {
    MessaggioEl,
  },
  mixins: [messaggi_mixin_archivio],
  computed: {},
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  overflow-y: scroll;
  height: 320px;
}
</style>
