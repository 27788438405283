<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :fullscreen="is_mobile"
    :scrollable="is_mobile"
  >
    <v-card :img="require('@/assets/chat_bg_2.jpg')">
      <v-card-title class="white--text font-weight-light blue lighten-1">
        <v-list-item class="">
          <v-list-item-avatar tile size="40">
            <v-avatar color="white" size="40">
              <v-icon color="grey">mdi-account</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="white--text">
              {{ chat.autore.nome }}
            </v-list-item-title>
            <v-list-item-subtitle class="white--text">
              <template v-if="chat.autore.is_connected">
                <v-avatar color="light-green" size="10" class="mr-1" />
                <span class="white--text">Online</span>
              </template>
              <template v-else> Ultimo accesso {{ last_access }} </template>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-title>
      <v-card-text class="chat_wrapper mt-2" ref="chat_body">
        <template v-if="chat != []">
          <template v-for="giorno in msg_array">
            <v-row class="my-2" :key="giorno.data" justify="center">
              <v-card max-width="300" elevation="1" class="blue lighten-5 pa-1">
                <span style="font-size: 12px" class="">
                  {{ giorno.data }}
                </span>
              </v-card>
            </v-row>
            <Messaggio
              v-for="messaggio in giorno.msg"
              :key="messaggio.id"
              :messaggio="messaggio"
              :id_conversazione="chat._id"
              :chat_visibile="dialog"
            ></Messaggio>
          </template>
        </template>
      </v-card-text>
      <v-card-actions class="white">
        <v-spacer />
        <v-dialog v-model="delete_dialog" width="400">
          <template v-slot:activator="{ on }">
            <v-btn outlined color="red" v-on="on">
              <v-icon class="ml-2">mdi-delete</v-icon>
              elimina
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="red--text"> Elimina Chat </v-card-title>
            <v-card-text>
              La chat verrà eliminata.
              <br />
              Procedere?
            </v-card-text>
            <v-card-actions>
              <v-spacer> </v-spacer>
              <v-btn
                outlined
                color="red"
                :loading="delete_loading"
                @click="delete_chat()"
              >
                <v-icon class="mr-2">mdi-delete</v-icon>
                Elimina
              </v-btn>
              <v-btn outlined color="grey" @click="delete_dialog = false">
                Annulla
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn text color="grey" @click="close()">Chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import chat_main_mixin from "../mixin/chat_main_archivio_mixin";
import Messaggio from "./chat_messaggio";

export default {
  components: {
    Messaggio,
  },
  props: {
    show: Boolean,
    chat: Object,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    is_mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch: {
    show: function () {
      this.dialog = this.show;
    },
    $route(to, from) {
      if (from.hash === "#dettaglio-chat" && this.dialog) {
        this.dialog = false;
      }
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.$router.push("#dettaglio-chat");
    },
    close() {
      this.dialog = false;
      this.$router.back();
    },
  },
  mixins: [chat_main_mixin],
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.chat_wrapper {
  height: 500px;
  overflow-y: scroll;
}
</style>