<template>
  <v-list-item  @click="$refs.messaggio_dialog.open()" class="py-1"  @contextmenu="show_del_menu">
    <v-list-item-avatar :class="$vuetify.breakpoint.mobile ? 'ml-n4': ''">
      <v-avatar color="grey lighten-2" size="40">
        <v-icon color="white"> mdi-account </v-icon>
      </v-avatar>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        {{ chat.autore.nome }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="ultimo_msg" class="text-truncate">
        <template v-if="ultimo_msg.msg.isText">
          {{ ultimo_msg.msg.testo }}
        </template>
        <template v-if="ultimo_msg.msg.isFile">
          <v-icon color="blue" size="15" class="mr-2">mdi-file</v-icon>
          {{ ultimo_msg.msg.file_name }}.{{ ultimo_msg.msg.file_type }}
        </template>
        <template v-if="ultimo_msg.msg.isImg">
          <v-icon color="purple" size="15" class="mr-2">mdi-camera</v-icon> Foto
        </template>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action-text v-if="ultimo_msg">
      {{ ultimo_msg.data }}
    </v-list-item-action-text>
    <MessaggioDialog :show="show" :chat="chat" @close="show = false" ref="messaggio_dialog"/>
    <v-menu
      v-model="del_menu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="del_chat()">
          <v-list-item-icon>
            <v-icon color="red">mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Elimina</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item>
</template>

<script>
import moment from "moment";
import MessaggioDialog from "./messaggio_dialog_archivio.vue";
export default {
  components: {
    MessaggioDialog,
  },
  props: {
    chat: Object,
  },
  data() {
    return {
      show: false,
      del_menu: false,
      y: 0,
      x: 0,
    };
  },
  computed: {
    iniziale() {
      return this.chat.autore.nome.slice(0, 1);
    },
    ultimo_msg() {
      if (this.chat.messaggi.length) {
        return {
          msg: this.chat.messaggi.at(-1),
          data: moment.unix(this.chat.messaggi.at(-1).ts_creazione).calendar(),
        };
      } else {
        return null;
      }
    },
    n_non_letti() {
      return this.$store.getters.get_chat_nl(this.chat._id);
    },
  },
  methods: {
    show_del_menu(e) {
      e.preventDefault();
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.del_menu = true;
      });
    },
    del_chat(){
      this.$store.dispatch("delete_chat", this.chat._id)
    },
  },
};
</script>