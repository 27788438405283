<template>
  <!-- <v-list> -->
  <div>
    <ChatElement v-for="chat in wamsgs" :chat="chat" :key="chat._id" />
  </div>

  <!-- </v-list> -->
</template>

<script>
import ChatElement from "../desktop/wachat_element.vue";
export default {
  components: {
    ChatElement,
  },
  computed: {
    wamsgs() {
      return this.$store.getters.get_wamsgs;
    },
  },
};
</script>
