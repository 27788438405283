<template>
  <ChatDesktop v-if="!$vuetify.breakpoint.mobile" />
  <ChatMobile v-else/>
</template>

<script>
import ChatDesktop from "@/components/Chat/desktop/chat_desktop.vue"
import ChatMobile from "@/components/Chat/mobile/chat_mobile.vue"
export default {
  components: {
    ChatDesktop,
    ChatMobile
  },
};
</script>