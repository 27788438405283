<template>
  <v-dialog v-model="dialog" fullscreen scrollable>
    <v-card>
      <v-card-title>
        <v-text-field
          outlined
          prepend-inner-icon="mdi-magnify"
          label="Cerca ..."
          v-model="search_text"
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="item in lista_clienti"
            :key="item.id"
            @click="inizia_chat(item.iva)"
          >
            <v-list-item-avatar color="blue">
              <v-icon color="white">mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.nome }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text color="grey" @click="close()">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      search_text: "",
    };
  },
  watch: {
    $route(to, from) {
      if (from.hash === "#nuova-chat" && this.dialog) {
        this.dialog = false;
      }
    },
  },
  computed: {
    lista_clienti() {
      let regex = new RegExp(this.search_text, "i");
      return this.$store.getters.get_clienti.filter((cliente) =>
        regex.test(cliente.nome)
      );
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$router.back();
    },
    open() {
      this.dialog = true;
      this.$router.push("#nuova-chat");
    },
    inizia_chat(iva) {
      this.$store
        .dispatch("start_new_chat", iva)
        .then((res) => {
          this.$emit("chat_start", res);
          this.close();
        })
        .catch((err) => {
          if (err.response.status == 409) {
            alert("Chat già attiva");
          }
        })
        .finally(() => {
          this.menu = false;
        });
    },
  },
};
</script>