<template>
  <WhatsappMobile />
</template>

<script>
import WhatsappMobile from "@/components/Chat/mobile/wa_chat_mobile.vue"
export default {
  components: {
    WhatsappMobile
  },
};
</script>