<template>
  <div>
    <v-autocomplete
      v-model="iva_cliente"
      :items="clienti"
      hide-details
      outlined
      dense
      rounded
      label="Cerca..."
      class="mt-4"
      item-text="nome"
      item-value="iva"
      @change="ricerca()"
      clearable
    ></v-autocomplete>
    <v-list>
      <template v-for="msg in chats">
        <ChatEl :key="msg.id" :chat="msg" />
      </template>
    </v-list>
  </div>
</template>

<script>
import chat_mixin from "../mixin/messaggi_mixin_archivio";
import ChatEl from "../desktop/messaggio_element_archivio.vue";
export default {
  components: {
    ChatEl,
  },
  mixins: [chat_mixin],
};
</script>