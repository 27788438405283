<template>
    <v-row>
        <v-col cols="12">
            <ChatAttive />
        </v-col>
        <v-col cols="12">
            <ChatArchivio/>
        </v-col>
    </v-row>
</template>

<script>
import ChatAttive from "./chat_attive.vue";
import ChatArchivio from "./chat_archivio.vue";
export default {
  components: {
    ChatAttive,
    ChatArchivio,
  },
};
</script>