import moment from "moment";
import _ from "lodash";
export default {
  data() {
    return {
      menu: false,
      dialog: false,
      delete_dialog: false,
      delete_loading: false,
    };
  },
  created() {},
  watch: {
    dialog: function () {
      if (this.dialog) {
        setTimeout(() => {
          this.scroll_down();
        }, 500);
      } else {
        this.$emit("close");
      }
    },
    chat: {
      deep: true,
      handler() {
        if (this.dialog) {
          setTimeout(() => {
            this.scroll_down();
          }, 100);
        }
      },
    },
  },
  computed: {
    last_access() {
      return moment.unix(this.chat.destinatario.last_access).calendar();
    },
    msg_array() {
      if (this.chat.messaggi == undefined) {
        return [];
      }
      let res = _.groupBy(this.chat.messaggi, (msg) => {
        return moment.unix(msg.ts_creazione).startOf("day");
      });
      let arrbyday = Object.keys(res).map((date) => {
        return {
          data: moment(date).calendar({
            sameDay: "[Oggi]",
            nextDay: "[Domani]",
            nextWeek: "dddd",
            lastDay: "[Ieri]",
            lastWeek: "[Lo Scorso] dddd",
            sameElse: "DD/MM/YYYY",
          }),
          msg: res[date],
        };
      });
      return arrbyday;
    },
  },
  methods: {
    scroll_down() {
      this.$refs.chat_body.scroll({ top: 9999 });
    },
    delete_chat() {
      this.delete_loading = true;
      this.$store.dispatch("delete_chat", this.chat._id).then(() => {
        this.delete_loading = false;
        this.delete_dialog = false;
        this.dialog = false;
      });
    },
  },
};
