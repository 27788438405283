<template>
  <div>
    <v-tabs color="green">
      <v-tab>Attive</v-tab>
      <v-tab>Archivio</v-tab>
      <v-tab>Annunci</v-tab>
      <v-tab-item>
        <ChatAttive />
      </v-tab-item>
      <v-tab-item>
        <ChatArchivio />
      </v-tab-item>
      <v-tab-item>
        <AnnunciMobile/>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import ChatAttive from "../mobile/chat_attive_mobile.vue";
import ChatArchivio from "../mobile/chat_archivio_mobile.vue";
import AnnunciMobile from "../mobile/annunci_mobile.vue"
export default {
  components: {
    ChatAttive,
    ChatArchivio,
    AnnunciMobile
  },
};
</script>