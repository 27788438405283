<template>
  <div>
    <v-list>
      <template v-for="msg in chats">
        <ChatEl :key="msg.id" :chat="msg" />
      </template>
    </v-list>
    <v-speed-dial v-model="fab" fixed bottom right direction="top">
      <template v-slot:activator>
        <v-btn fab dark color="blue" v-model="fab">
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-plus </v-icon>
        </v-btn>
      </template>
      <v-btn fab color="blue" @click="$refs.nuova_chat.open()"
        ><v-icon color="white">mdi-chat</v-icon></v-btn
      >
      <v-btn fab color="purple" @click="$refs.nuovo_broadcast.open()"
        ><v-icon color="white">mdi-bullhorn-variant</v-icon></v-btn
      >
    </v-speed-dial>

    <NuovaChat ref="nuova_chat" @chat_start="open_chat_dialog" />
    <NuovoBroadcast ref="nuovo_broadcast"/>
    <ChatDialog
      ref="chat_dialog"
      v-if="chat"
      :chat="chat"
      @chat_archived="close_chat_dialog"
    />
  </div>
</template>

<script>
import chat_mixin from "../mixin/messaggi_mixin";
import ChatEl from "../desktop/messaggio_element.vue";
import NuovaChat from "./nuova_chat_mobile.vue";
import NuovoBroadcast from "./nuovo_broadcast_mobile.vue"
import ChatDialog from "../desktop/messaggio_dialog.vue";

export default {
  components: {
    ChatEl,
    NuovaChat,
    ChatDialog,
    NuovoBroadcast
  },
  mixins: [chat_mixin],
  data() {
    return {
      fab: false,
    };
  },
};
</script>