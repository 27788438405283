<template>
  <v-list>
    <v-list-item v-for="broad in broadcasts" :key="broad._id.$oid">
      <v-list-item-avatar>
        <v-icon color="green">mdi-whatsapp</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-content>
          <v-list-item-title>
            {{ broad.testo }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ data(broad.ts_spedizione) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item-content>
      <v-list-item-action>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on"><v-icon>mdi-eye</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="elemento in broad.elementi"
              :key="elemento.destinatario"
            >
              <v-list-item-content>
                <v-list-title>{{
                  cliente(elemento.destinatario).nome
                }}</v-list-title>
                <v-list-item-subtitle>
                  <v-icon size="20" :color="colore_icona(elemento.stato)">{{
                    stato_icona(elemento.stato)
                  }}</v-icon>
                  {{ elemento.stato }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
      <v-list-item-action-text>
        {{ broad.elementi.length }} Destinatari
      </v-list-item-action-text>
    </v-list-item>
  </v-list>
</template>

<script>
import moment from "moment";
export default {
  methods: {
    data(ts) {
      return moment.unix(ts).format("dddd DD MMM hh:mm");
    },
    stato_icona(text) {
      if (text == "error" || text == "failed") {
        return "mdi-close";
      } else if (text == "sent") {
        return "mdi-check";
      } else {
        return "mdi-check-all";
      }
    },
    colore_icona(text) {
      if (text == "error" || text == "failed") {
        return "red";
      } else if (text == "read") {
        return "blue";
      } else {
        return "grey";
      }
    },
    cliente(iva) {
      return this.$store.getters.get_cliente_by_iva(iva);
    },
  },
  computed: {
    broadcasts() {
      return this.$store.getters.get_broadcasts;
    },
  },
};
</script>
